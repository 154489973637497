import { createContext, ReactNode, useEffect, useContext } from "react";
import mixpanel, { OverridedMixpanel } from "mixpanel-browser";
import useUserSession from "./useUserSession";
import { MixpanelEvents } from "../types/types_analytics";

// Note: Next.js 10 automatically sets NODE_ENV even for browser environments
mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN!, {
    debug: process.env.NODE_ENV === "development",
});

const AnalyticsContext = createContext<{
    mixpanel: OverridedMixpanel;
}>({
    mixpanel: mixpanel,
});

export function AnalyticsProvider({ children }: { children?: ReactNode }) {
    const { sessionData } = useUserSession();

    useEffect(() => {
        if (sessionData?.session?.user) {
            mixpanel.identify(sessionData.session.user.id);
            mixpanel.people.set({
                $email: sessionData.session.user.email,
            });
        } else {
            mixpanel.reset();
        }
    }, [sessionData]);

    return (
        <AnalyticsContext.Provider value={{ mixpanel }}>
            {children}
        </AnalyticsContext.Provider>
    );
}

export function useAnalytics() {
    return { ...useContext(AnalyticsContext), MixpanelEvents: MixpanelEvents };
}
