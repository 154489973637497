import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { PostgrestError } from "@supabase/supabase-js";
import { Link, Research, Result } from "../types/types";

type TableName = "researches" | "links" | "results";

type Response = {
    data: Research[] | Link[] | Result[] | null;
    error: PostgrestError | null;
};

export type Query<T> = {
    isLoading: boolean;
    data:
        | (T extends "researches"
              ? Research[]
              : T extends "links"
              ? Link[]
              : T extends "results"
              ? Result[]
              : any[])
        | null
        | undefined;
    refetch: () => void;
};

export default function useSupaTable<T extends TableName>(tableName: T) {
    const supabase = useSupabaseClient();

    const { isLoading, data, refetch }: Query<T> = useQuery({
        queryKey: [tableName],
        queryFn: async () => {
            // Ref: https://github.com/supabase/postgrest-js/issues/388
            // @ts-expect-error
            const { data, error }: Response = await supabase
                .from(tableName)
                .select("*");
            if (error) throw error;
            return data;
        },
    });

    useEffect(() => {
        supabase
            .channel("db-changes")
            .on(
                "postgres_changes",
                {
                    event: "*",
                    schema: "public",
                    table: tableName,
                },
                () => refetch()
            )
            .subscribe();
    }, []);

    return { isLoading, data, refetch };
}
