import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { PostgrestError } from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Subscription, UserDetails } from "../types/types";
import useUserSession from "./useUserSession";

type UserResponse = {
    data: UserDetails | null;
    error: PostgrestError | null;
};

type SubscriptionResponse = {
    data: Subscription | null;
    error: PostgrestError | null;
};

export default function useUserInfo() {
    const supabase = useSupabaseClient();
    const {
        isLoading: isUserLoading,
        data: userData,
        refetch: userRefetch,
    } = useQuery({
        queryKey: ["user"],
        queryFn: async () => {
            const { data, error }: UserResponse = await supabase
                .from("users")
                .select("*")
                .single();
            if (error) throw error;
            return data;
        },
    });

    const {
        isLoading: isSubscriptionLoading,
        data: subscriptionData,
        refetch: subscriptionRefetch,
    } = useQuery({
        queryKey: ["subscription"],
        queryFn: async () => {
            const { data, error }: SubscriptionResponse = await supabase
                .from("subscriptions")
                .select("*, prices(*, products(*))")
                .in("status", ["trialing", "active"])
                .maybeSingle();
            if (error) throw error;
            return data;
        },
    });

    useEffect(() => {
        supabase
            .channel("db-changes")
            .on(
                "postgres_changes",
                {
                    event: "*",
                    schema: "public",
                    table: "users",
                },
                () => userRefetch()
            )
            .on(
                "postgres_changes",
                {
                    event: "*",
                    schema: "public",
                    table: "subscriptions",
                },
                () => subscriptionRefetch()
            )
            .subscribe();
    }, []);

    const isLoading = isUserLoading || isSubscriptionLoading;

    return {
        isLoading,
        isUserLoading,
        isSubscriptionLoading,
        userData,
        subscriptionData,
    };
}

export function useOnboarding() {
    const supabase = useSupabaseClient();
    const { sessionData } = useUserSession();

    const finishOnboarding = async () => {
        const { error } = await supabase
            .from("users")
            .update({ is_onboarded: true })
            .eq("id", sessionData?.session?.user.id)
            .single();

        if (error) {
            console.error(error);
            return {
                success: false,
            };
        }
        return {
            success: true,
        };
    };

    return { finishOnboarding };
}
