import { createContext, ReactNode, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import AlertsContainer from "../components/AlertsContainer";

export type AlertPrototype = {
  accent: "success" | "error";
  text: string;
};

export interface Alert extends AlertPrototype {
  uuid: string;
}

interface AlertProviderProps {
  children?: ReactNode;
}

const AlertsContext = createContext<{
  alerts: Alert[];
  addAlert: (msg: AlertPrototype) => void;
}>({
  alerts: [],
  addAlert: () => undefined,
});

export function AlertProvider({ children }: AlertProviderProps) {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  /*
    An array containing objects with:
    {
      accent: "success" | "error",
      text: "<message>",
      uuid: uuidv4() // Used as the key for React and Transition group rendering
    }
  */

  const addAlert = (msg: AlertPrototype) => {
    setAlerts((prevAlerts) => {
      return prevAlerts.concat([{ ...msg, uuid: uuidv4() }]);
    });
    setTimeout(() => {
      setAlerts((prevAlerts) => prevAlerts.slice(1));
    }, 5000);
  };

  return (
    <AlertsContext.Provider value={{ alerts, addAlert }}>
      {children}
      <AlertsContainer alerts={alerts} />
    </AlertsContext.Provider>
  );
}

export function useAlerts() {
  return useContext(AlertsContext);
}
