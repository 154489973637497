import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

// check user login
export default function useUserSession() {
    const supabase = useSupabaseClient();
    const {
        isLoading,
        data: sessionData,
        refetch,
    } = useQuery({
        queryKey: ["session"],
        queryFn: async () => {
            const { data, error } = await supabase.auth.getSession(); // check if client side's cookie is authenticated
            if (error) throw error;
            return data;
        },
    });

    useEffect(() => {
        supabase.auth.onAuthStateChange(() => refetch); // supabase.auth is related to user-login
    }, []);

    const isSignedIn: Boolean =
        sessionData?.session !== null && sessionData?.session !== undefined;

    return { isLoading, isSignedIn, sessionData };
}
