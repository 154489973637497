export const GA_TRACKING_ID = "G-LK4MDXQZGV"; // change this to env variable

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL) => {
    window.gtag("config", GA_TRACKING_ID, {
        page_path: url,
    });
};

type GTagEvent = {
    action: string;
    category: string;
    label: string;
    value: string;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent) => {
    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
    });
};
// export const event = ({ action, category, label, value }: GTagEvent) => {
//     window.gtag("event", action, {
//         event_category: category,
//         event_label: label,
//         value: value,
//     });
// };
