import { Alert } from "../../hooks/useAlerts";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Alert as ReachAlert } from "@reach/alert";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import AlertStyles from "./AlertsContainer.module.css";

function AlertBox({
  accent,
  text,
}: {
  accent: "success" | "error";
  text: string;
  key: string;
}) {
  return (
    <div
      className={`py-2 pl-5 pr-6 ${
        accent === "success" ? "bg-slate-700" : "bg-rose-600"
      } rounded-lg gap-4 flex flex-row justify-between items-center drop-shadow-md`}
    >
      {accent === "success" ? (
        <CheckIcon className="inline w-5 h-5 text-white" />
      ) : (
        <XMarkIcon className="inline w-5 h-5 text-white" />
      )}
      <span className="text-white font-medium text-base">
        <ReachAlert type="polite">{text}</ReachAlert>
      </span>
    </div>
  );
}

export default function AlertsContainer({ alerts }: { alerts: Alert[] }) {
  return (
    <div className="fixed bottom-0 left-0 right-8 mb-8 flex flex-col items-end gap-4 pointer-events-none">
      <TransitionGroup component={null}>
        {alerts.map((alert) => (
          <CSSTransition
            key={alert.uuid}
            classNames={AlertStyles}
            timeout={300}
          >
            <AlertBox
              accent={alert.accent}
              text={alert.text}
              key={alert.uuid}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
}
