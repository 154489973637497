import { ReactNode } from "react";
import useUserInfo from "../../hooks/useUserInfo";
import useUserSession from "../../hooks/useUserSession";
import useSupaTable from "../../hooks/useSupaTable";

interface BackendListenerProps {
    children?: ReactNode;
}

export default function BackendListener({ children }: BackendListenerProps) {
    useUserSession();
    useUserInfo();
    useSupaTable("researches");
    useSupaTable("links");
    useSupaTable("results");
    return <>{children}</>;
}
