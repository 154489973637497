import "../styles/globals.css";
import { ReactNode, useState } from "react";
import type { AppProps } from "next/app";
import Head from "next/head";
import { Inter } from "@next/font/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createBrowserSupabaseClient } from "@supabase/auth-helpers-nextjs";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import BackendListener from "../components/BackendListener";
import { AlertProvider } from "../hooks/useAlerts";
import { AnalyticsProvider } from "../hooks/useAnalytics";
import Script from "next/script";
import { useEffect } from "react";
import { useRouter } from "next/router";
import * as gtag from "../utils/gtag";

const inter = Inter({ subsets: ["latin"], variable: "--font-inter" });

export function NextFontProvider({ children }: { children?: ReactNode }) {
  return (
    <div id="__font" className={`${inter.variable} font-sans`}>
      {children}
    </div>
  );
}

function TrackingScripts() {
  return (
    <>
      {/* Google tag (gtag.js) for analytics and conversion tracking  */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=G-LK4MDXQZGV`}
      ></Script>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-LK4MDXQZGV', {
              page_path: window.location.pathname,
          });
          gtag('config', 'AW-11313136870');
        `}
      </Script>
      {/* Bing tag (bat.js) for analytics and conversion tracking  */}
      <Script id="bing-analytics" strategy="afterInteractive">
        {`
          (function(w,d,t,r,u)
          {
            var f,n,i;
            w[u]=w[u]||[],f=function()
            {
              var o={ti:"187073320", enableAutoSpaTracking: true};
              o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
            },
            n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
            {
              var s=this.readyState;
              s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
            },
            i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
          })
          (window,document,"script","//bat.bing.com/bat.js","uetq");
      `}
      </Script>
    </>
  );
}

export default function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());
  const [supabase] = useState(() => createBrowserSupabaseClient());
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <TrackingScripts />
      <SessionContextProvider
        supabaseClient={supabase}
        initialSession={pageProps.initialSession}
      >
        <QueryClientProvider client={queryClient}>
          <BackendListener>
            <AnalyticsProvider>
              <NextFontProvider>
                <AlertProvider>
                  <Component {...pageProps} />
                </AlertProvider>
              </NextFontProvider>
            </AnalyticsProvider>
            <ReactQueryDevtools />
          </BackendListener>
        </QueryClientProvider>
      </SessionContextProvider>
    </>
  );
}
